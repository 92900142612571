import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      appname: "SPS Fulfillment",
      title: "We Search, Package and <br /> Ship Your Products",
      subtitle:"Let us take the stress out of ecommerce and selling online. <br />We offer an all-in-one solution to simplify your experience. <br />Trust in our comprehensive services and simplify your selling process.",
        contact:"Contact Us",
        aboutSummary:"Our Mission is to make online selling as easy as never before. We, as company, concentrate on excellent customer service. Our team of professional assistance can solve every inconvenience and make your journey with us as pleasant as possible. <br /> <br />SPS is the most versatile Fulfillment service among all. The company adapts to the customer not viceversa and accepts every special request you have in your mind.",
        benefitsubtitle: "Enhance Your Experience with our Numerous Benefits <br /> A world of benefits for a better ecommerce experience ",
        benefittitle: "Unlock Endless Possibilities with Our Bundle of Benefits",
        Orderprocesstitle:"An easy process to <br /> start selling with us",
        titlespecialoffer:"Explore Our Affordable Pricing Options",
        subtitlespecialoffer:"Discover the Convenience of SPS: Experience our standard delivery plans <br /> and unlock added benefits with our Cash on Delivery options.",
        footerespecialoffer: "Enjoy Unlimited Savings with No Monthly Costs!",

        normaldeliverycard: "Experience the Speed and Convenience of Normal Delivery",
        cashdeliverycard: "Enjoy the Ease of Cash on Delivery: Pay Only Upon Receipt",
        NormalDelivery:"Normal Delivery",
        CashOnDelivery:"Cash On Delivery",
        timeShipping: "48H Shipping",
        Startingat: "Starting at",
        Benefits: "Benefits",
        Buttonpricing: "More about pricing",
        Benefit1:"No monthly warehouse fees",
        Benefit2:"Low shipping cost",
        Benefit3:"7/7 personal assistance",
        Benefit4:"Searching the ideal product for the lowest price",
        Benefit5:"Personalizing package and product",
        Benefit6:"Sales assistance",

        Testimonial1:"SPS Always provides the best results for each of our projects, One that we believe in building a safe delivery package! <br /> Trusted to order again",
        Testimonial2: "SPS has truly exceeded my expectations. Their commitment to delivering a safe and reliable package made me confident in choosing them again and again. <br /> Thank you, SPS, for making my online shopping experience hassle-free!",

        Titlefooter: "So, what are you waiting for now? Let’s be part of us now",
        Subtitlefooter: "Take advantage of all that we have to offer and elevate your ecommerce experience. <br /> Don't miss out on the benefits of being a part of the SPS Family.",
        FooterOrderNow:"Order Now",
        Footertitle2: "Always give the best result for every order we have!",
        FooterCompany:"Company",
        FooterAboutus: "About Us",
        FooterContactus: "Contact Us",
        FooterBestSellers: "Best Sellers",
        FooterPricing: "Pricing",
        FooterResearch: "Research",
        FooterService: "Services",
        FooterService2: "Service",
        FooterCompleteService: "Complete Service",
        FooterGoodstous: "Your Goods to Our Warehouse",
        FooterGoodstothem: "Our Goods to Your warehouse",
        FooterOurPlace: "Our Place",
        FooterAdress: "Via Borgonuovo 9 Milano | 20121",
        FooterPhone: "+39 02 9713 4155",

        Aboutustitle:"Make selling online easy <br /> and fast like never before",
        Ourmissiontitle: "Our mission",
        Meetourteamtitle: "Meet our team",
        Teamsubtitle: "The work we produce is always based on ideas trapped in our brains!",
        Teamtext: "<br /><br />Our team is made up of a dedicated group of individuals who share the passion for making ecommerce easier for everyone. We are united by our commitment to making the ecommerce process easier and more efficient for our customers, and we work hard every day to achieve that goal. Our team members possess a wide range of skills and competencies, including those in customer service, logistics, sales, and developing websites, which allows us to offer a comprehensive and tailored service to our clients.<br /><br />  Ultimately, our team is made up of individuals who are motivated by the desire to make a positive impact in the ecommerce industry. We believe that by working together and leveraging our unique skills and experiences, we can achieve our goal of making ecommerce easier for everyone",
        Joinourteam:"Join Our Team",
        futureplantitle: "Unleash Effortless Deliveries with Our Exciting Future Plans",
        futureplantext: "As we look towards the future, we have ambitious plans for expansion. Our goal is to open more warehouses in various locations around the world, enabling our customers to sell globally with ease. In addition, we will be releasing an updated website with a personal dashboard for each client, as well as other exciting features. We are committed to continuously innovating and staying at the forefront of technology to enhance the experience for our valued customers. We hope to grow alongside our clients and make every day better than the last.",
        SendFeedbackbutton: "Send Feedback",

        Pricingsubtitle: "Affordable Shipping Fees for Every Order:<br /> Enjoy Convenient Delivery with Our Competitive Shipping Costs.",
        Pricingtitle: "Different plans for different needs",
        Shippingcosttitle: "Shipping Cost",
        Shippingcostsubtitle: "Say goodbye to hidden shipping and handling costs! <br /><br /> At SPS Fulfillment, we believe in transparent pricing and offer a flat handling fee for every product ordered. Our innovative approach to ecommerce fulfillment allows us to contract with multiple carriers, providing the most cost-effective option for each shipment.",
        Handlingfeetitle: "Handling Fees",
        Handlingfeesubtitle: "Experience seamless and efficient handling with every product you order at SPS.<br /><br /> Our fixed handling fee includes personalized assistance, professional packaging, secure warehouse storage and much more, ensuring a hassle-free shopping experience for you.",
        Titlemap: "We Ship To Over 10+ Countries",
        Perunit: "/ per unit",
        Itemweight:"Item Weight",
        Itemsize:"Item Size",

        Bestsellertitle: "SPS Dropshipping",
        sortproduct:"Sort Product By",
        category1:"Best Seller",
        category2:"Best Rating",
        categorytitle:"Categories",
        category3: "Women’s Fashion",
        category4: "Men’s Fashion",
        category5: "Phones & Telecommunications",
        category6: "Computer, Office, & Security",
        category7: "Consumer Electronics",
        Filter:"Filter",
        Loadmore:"Load More",
        productnumber:"200 Product by Best Seller",




        Researchtitle: "Get your analysis in 2 days",
        Researchsubtitle:"Try out our new service. We search best selling products for your shop or FBA. <br /> Unlimited review until you are not satisfied of the product.",
        titlefirstcard:"Access to thousands of high-converting products",
        subtitlefirstcard:"You don't need to be confused anymore to find products because we have provided a library for all of these products",
        titlesecondcard:"Get recommendations of high-quality products",
        subtitlesecondcard:"We will provide continuous recommendations regarding the best products available at that time",
        titlethirdcard:"Facebook audience finder",
        subtitlethirdcard:"We will help you get the right target audience for marketing using Facebook so you get the best results",
        secondtitleresearch:"For every research, We provide the following",
        firstbenefittitle:"01. Product analysis",
        firstbenefitsubtitle:"We ensure that you provide data from the analysis that we have on each product so that you get the best product",
        secondbenefittitle:"02. Optimized product description",
        secondbenefitsubtitle:"We have a team that can help you compile the best description for each product that you will sell in order to get maximum results",
        thirdbenefittitle:"03. High-quality video ads & thumbnail",
        thirdbenefitsubtitle:"We make sure that every product we have will have a professional video ad layout and also have the best photos",
        fourthbenefittitle:"04. Facebook targeting",
        fourthbenefitsubtitle:"We will give you suggestions for your target in maximizing sales with the help of Facebook",
        fivebenefittitle:"05. Custom product review",
        fivebenefitsubtitle:"We will display every review that is on every product that we have, the review is taken from Amazon, and Alibaba",
        sixbenefittitle:"06. Competitor data & trend insights",
        sixbenefitsubtitle:"We will always provide the latest data regarding each of your competitors along with the trends that were in effect at that time",
        sevenbenefittitle:"07. Find winning product from several niches",
        sevenbenefitsubtitle:"Dizzy looking for the best product? Leave it to us then we will find the best product for you along with the profit info of the product",
        eightbenefittitle:"08. Real time spy tool!",
        eightbenefitsubtitle:"Providing data in the form of the latest emerging products, this data will be updated every time so that it follows the ongoing trend",
        buttonresearch:"Try Out Now",



        Ourservicetitle: "Our Services",
        Ourservicesubtitle: "We offer a versatile service, from product research to fulfillment, package and product personalization, cash on delivery and much more. The list is long, and every customer can express his request. This is what makes us different from our competitors. <br /> Contact us, we research and order the products for you, store it in our warehouse and ship it to your clients. As easy as it sounds, we do all the work for you.",
        Completeservicetitle: "Complete Service",
        Completeservice: "Welcome to our Complete Service, designed to make your ecommerce experience as seamless and stress-free as possible. With this service, you can sit back and let our team do all the work for you. We'll research and compare prices from thousands of different suppliers to find the best deal on the products you need.<br /> We'll handle the ordering, packaging (including personalization options like thank-you cards, if desired), and shipping to your customer's doorstep within 24-48 hours.",
        YourGoodstoOurWarehousetitle: "Your Goods to Our Warehouse",
        YourGoodstoOurWarehousesubtitle: "If you already have your products and just need help with fulfillment and shipping, our Your Goods to Our Warehouse service is the perfect solution. This service includes everything from packaging and personalization to fast and efficient delivery to your customers, all while taking advantage of our state-of-the-art warehouse facilities. Simply send your goods to us and we'll handle the rest, making the process as hassle-free as possible.<br /> Contact us today to learn more and get started.",
        OurGoodstoYourWarehousetitle: "Our Goods to Your Warehouse",
        OurGoodstoYourWarehousesubtitle: "If you're looking for assistance with product research and sourcing, our Our Goods to Your Warehouse service is here to help. We'll scour the market for the best deals on the products you need and have them delivered straight to your warehouse. This allows you to focus on other aspects of your business while we handle the legwork of finding the best products at the best prices.<br />  Contact us today to learn more and take the first step towards growing your business.",
        SalesAssistancetitle: "We pay for your stock",
        SalesAssistancesubtitle: "SPS Fulfillment is the only company that offers the stock advance service. Choose the product you want and we will advance the cost of the entire stock. All you have to do is sell and pay it back after you have collected your profit. This way, you have initial liquidity to grow your business.",
        Dropshippingtitle: "Dropshipping",
        Dropshippingsubtitle: "Our Dropshipping service is a unique offering designed to help you test out new products and build your online store without the hassle of inventory management. With this service, you can offer a wide range of products to your customers without the need to actually stock and manage the inventory yourself.<br /> Ask our team what products we have in stock.",
        Callcentertitle: "Call Center",
        Callcentersubtitle: "Our call center subscription is designed to maximize the automation of your business. Our team of experts verifies and confirms each order before shipment to avoid any payment problems upon delivery. In the case of delivery or product return issues, we will take care of the resolution so you can relax while your business grows exponentially.",

        titlecontactus: "Bring Your Vision to Life with Our Support: Get in Touch Today",
        subtitlecontactus: "If you have a question or you want to join our team you can directly <br /> send us a message and we will reply in 24 hours",
        name:"Name",
        phonenumber: "Phone Number",
        email: "Email",
        yourquestion:"Your Question",
        contactusbutton: "Send Question",

        Italy1:"Italy (Registered Office)",
        Italy2:"Italy (Warehouse)",
        Ouroffice:"Our Offices",
        Office1:"9 Via Borgonuovo, Milano 20121",
        Office2:"Affori, Milano 20162",

        question1:"How often is the cash on delivery remitted?",
        question2:"How do I connect my store to your platform?",
        question3:"Do I get a dashboard where I can track my deliverys?",
        answer1:"The frequency of remitting the cash on delivery is typically every 7 days, however, this can be adjusted and can be discussed with our team.",
        answer2:"Contact our team and once you ordered your products, we will send you a detailed video on how to connect your store. The status of your orders will be updated automatically on your store. It is very easy!",
        answer3:"Yes, sure. Our team will give you the access to a personal dashboard, where you can track all of the deliverys, and much more",

        thanksmessage: "Thank you for reaching out to us! <br /> We have received your message and will respond you within 24 hours.",

        tileorderpage:"Let us know what you want to order",
        subtitleorderpage: "Fill the form with your request and our team <br /> will response within 24 hours",
        whatproduct:"What Product are you looking for?",
        productquantity:"Product Quantity",
        productvariation:"Product Variation",
        SpecialRequest:"SpecialRequest",
        SpecialRequesttext:"*Do you have any special request we can help you with (Product or packaging customization, try out a product)? Whatever you like we will do our best to make it happen.",
        deliverydetails: "Delivery Details",
        StandardDelivery: "Standard Delivery",
        CashonDelivery:"Cash on Delivery",
        Services: "Services",
        CompleteService:"Complete Service",
        SendyourgoodstoourwarehouseServices:"Send your goods to our warehouse",
        Sendourgoodstoyourwarehouse: "Send our goods to your warehouse",
        SaleAssistance: "Sale Assistance",
        Yes:"Yes",
        No: "No",
        Howcontact: "How do you want to Get Contacted",
        WhatsAppNumber: "WhatsApp Number",
        addphoto: "Add (Photo, Link, Document)",
        upload:"Upload your photo / document here",
        SendQuestion: "Send Question",

        titleproductrose: "Woman Green Tshirt Double Size",
        productoverview: "Product Overview",
        productrosedescription: "House Decoration HandBlown Glass Domes With Base Wedding Gift Preserved Rose Glass Cloche Dome",
        titleanalytics: "Engagement Analytics",


      priceCards: {
        size: "Size",
        weight: "Weight",
        price: "Price",
      },
      headers: [
        {
          name: "Home",
          link: "/",
          color: "#fff",
        },
        {
          name: "About Us",
          link: "/about-us",
          color: "#000",
        },

        { name: "Products", 
          dropdown: [
            { name: "Dropshipping", link: "/best-seller", color: "#000" },
            { name: "Shop", link: "https://spsblog.myshopify.com/collections" , color: "#000"}
          ],
        },

        {
          name: "Pricing",
          link: "/pricing",

          color: "#fff",
        },
        {
          name: "Blog",
          link: "https://spsfulfillmentstore.store/blogs/sps-blog",

          color: "#fff",
        },
        {
          name: "Research",
          link: "/product-research",
          color: "#fff",
        },
        {
          name: "Services",
          link: "/our-services",
          color: "#fff",
        },
        {
          name: "Contact Us",
          link: "/contact-us",
          color: "#000",
        },
      ],
      benifit: [
        {
          id: 1,
          title: "Product Research",
          description:
            "Don't waste any more time struggling to find the best products at the best prices. Let our team of experts handle the legwork for you with our comprehensive product research service.",
          icon: "truck-delivery",
          route: "/our-services",
        },
        {
          id: 2,
          title: "24 Hour Delivery",
          description:
            "Get your products delivered in just 24 hours with our fast and efficient warehouse service. Choose SPS for all your fulfillment needs and enjoy lightning-fast delivery times.",
          icon: "user-search",
          route: "/our-services",
        },
        {
          id: 3,
          title: "We offer cash on delivery",
          description:
            "Cash on delivery is a popular option for many online shoppers and with SPS, you can offer it to your customers with ease. Choose SPS and take your business to the next level.",
          icon: "cash",
          route: "/pricing",
        },
        {
          id: 4,
          title: "We pay for your stock",
          description:
            "At SPS Fulfillment, we cover 100% of your stock costs, so you can focus on sales. Giving you an endless catalog of products. Choose SPS Fulfillment for your ecommerce!",
          icon: "cross",
          route: "/our-services",
        },
      ],
      order: [
        {
          id: 1,
          name: "Get started",
          description:
            "Send us the items you want, and we find the best deal for you, among our hundreds trusted suppliers. Choose SPS!",
          anchor: "Create Account",
          preview: "step-1",
          route: "/our-services",
        },
        {
          id: 2,
          name: "Get Quotation",
          description:
            "We will send you a detailed quotation with the inclusive price and shipping time. We take care of everything.",
          anchor: "Learn More",
          preview: "step-2",
          route: "/pricing",
        },
        {
          id: 3,
          name: "Order",
          description:
            "Confirm the quotation, and we will order and ship your desired inventory to our warehouse, from our trusted suppliers.",
          anchor: "Select Product",
          preview: "step-3",
          route: "/order",
        },
        {
          id: 4,
          name: "Start Selling",
          description:
            "Once we receive the inventory, we will sync with your sales channels so that orders automatically start flowing in.",
          anchor: "Learn More",
          preview: "step-1",
          route: "/best-seller",
        },
      ],
    },
  },
  it: {
    translation: {
            appname: "SPS Fulfillment",
      title: "Ricerchiamo, Imballiamo e <br /> Spediamo i tuoi prodotti",
      subtitle: "Togliti lo stress dell'ecommerce e della vendita online. <br /> SPS Fulfillment ti offre una soluzione completa che semplifica la tua attività.",
      contact: "Contattaci",
      aboutSummary: "La nostra missione è renderti la vendita online più semplice che mai. Come azienda desideriamo offrire un servizio di qualità ai nostri clienti. Il nostro team di esperti può risolvere ogni problema e rendere il tuo viaggio con noi il più piacevole possibile. <br /> <br />SPS è il servizio più versatile ed efficiente tra tutti. È l'azienda che si adatta al cliente e non viceversa e facciamo il possibile per accettare ogni tua richiesta particolare.",
      benefitsubtitle: "Migliora la tua esperienza con i nostri numerosi e unici servizi  <br /> Tantissimi vantaggi per goderti la miglior esperienza di ecommerce ",
      benefittitle: "Sblocca tantissime nuove funzioni con il nostro pacchetto di servizi vantaggiosi",
      Orderprocesstitle: "Un processo facile per <br /> iniziare a vendere con noi",
      titlespecialoffer: "Esplora le nostre tariffe super convenienti",
      subtitlespecialoffer: "Scopri la convenienza di SPS: sperimenta i nostri piani di consegna standard <br /> e approfitta dei nostri vantaggi con le opzioni di pagamento alla consegna.",
      footerespecialoffer: "Con le nostre tariffe risparmi e non hai costi fissi mensili!",
      normaldeliverycard: "Sperimenta la velocità e la convenienza della consegna normale",
      cashdeliverycard: "Goditi la comodità del pagamento alla consegna: paghi solo alla ricezione",
      NormalDelivery: "Consegna normale",
      CashOnDelivery: "Contrassegno",
      timeShipping: "Spedizione in 48 ore",
      Startingat: "A partire da",
      Benefits: "Vantaggi",
      Buttonpricing: "Maggiori informazioni sul prezzo",
      Benefit1: "Nessun costo mensile per il magazzino",
      Benefit2: "Basso costo di spedizione",
      Benefit3: "Assistenza 7/7",
      Benefit4: "Ricerca del prodotto ideale al prezzo più basso",
      Benefit5: "Personalizzazione della confezione e del prodotto",
      Benefit6: "Assistenza alla vendita",
      Testimonial1: "SPS fornisce sempre i migliori risultati per ognuno dei nostri progetti. SPS lavora per la realizzazione di un pacchetto di consegna sicuro!<br /> ",
      Testimonial2: "SPS ha davvero superato le mie aspettative. Il loro impegno nella consegna sicura e affidabile mi ha convinto nel sceglierli sempre nel futuro. <br />Grazie, SPS, per aver reso la mia esperienza di shopping online senza problemi!",
      Titlefooter: "Allora, cosa stai aspettando? Scegli SPS",
      Subtitlefooter: "Approfitta di tutto ciò che abbiamo da offrire e migliora la tua esperienza di ecommerce. <br /> Non perdere l'opportunità di far parte della famiglia SPS.",
      FooterOrderNow: "Ordina Ora",
      Footertitle2: "Il nostro obiettivo: dare sempre il massimo risultato per ogni ordine che riceviamo!",
      FooterCompany: "Azienda<br /> ",
      FooterAboutus: "Chi Siamo",
      FooterContactus: "Contattaci",
      FooterBestSellers: "I più venduti",
      FooterPricing: "Prezzi",
      FooterResearch: "Ricerca",
      FooterService: "Servizi",
      FooterService2: "Servizio",
      FooterCompleteService: "Servizio Completo",
      FooterGoodstous: "I tuoi prodotti nel nostro magazzino",
      FooterGoodstothem: "I nostri prodotti nel tuo magazzino",
      FooterOurPlace: "Dove Siamo",
      FooterAdress: "Via Borgonuovo 9 Milano | 20121",
      FooterPhone: "+39 02 9713 4155",

      Ourmissiontitle: "La nostra missione",
      Aboutustitle: "Rendere la vendita online facile <br /> e veloce come nessun altro è capace",
      Meetourteamtitle: "Conosci il team",
      Teamsubtitle: "Il lavoro che facciamo è basato sulle nuove idee generate dalla mente del nostro Team!",
      Teamtext: "<br /><br /> Il team di SPS Fulfillment è composto da un gruppo di persone che condivide la passione di rendere l'ecommerce più facile per tutti. Ci unisce l’impegno di rendere il processo di ecommerce più semplice ed efficiente per i nostri clienti e lavoriamo duramente ogni giorno per raggiungere questo obiettivo. I membri del nostro team possiedono una vasta gamma di competenze, tra cui quelle nel servizio clienti, nella logistica, nella vendita e nella gestione di siti Web. Tutto questo ci consente di offrire un servizio completo e personalizzato ai nostri clienti. <br /><br /> In definitiva, il nostro team è composto da persone motivate dal desiderio di migliorare l'industria dell'ecommerce. Crediamo che lavorando insieme e sfruttando le nostre capacità e la nostra esperienza si possa raggiungere l’obiettivo di rendere l'ecommerce più facile per tutti",
      Joinourteam: "Unisciti al nostro team",
      futureplantitle: "Rapide consegne senza sforzo con i nostri entusiasmanti piani futuri",
      futureplantext: "Per il futuro abbiamo ambiziosi piani di espansione. Il nostro obiettivo è aprire più magazzini, consentendo così ai nostri clienti di vendere in gran parte del mondo con facilità. Inoltre, pubblicheremo un sito web aggiornato con un pannello personale per ogni cliente, nonché altre uniche funzionalità emozionanti. Siamo impegnati nel continuare a innovare e a rimanere all'avanguardia della tecnologia per migliorare il servizio ai nostri preziosi clienti. Speriamo di crescere accanto a Voi e rendere il nostro servizio ogni giorno migliore ",
      SendFeedbackbutton: "Invia Feedback",

      Pricingsubtitle: "Spese di spedizione minime per ogni ordine:<br /> Usufruisci di una consegna conveniente con i nostri costi di spedizione super competitivi.",
      Pricingtitle: "Piani diversi per esigenze diverse",
      Shippingcosttitle: "Costo della spedizione",
      Shippingcostsubtitle: "Di' addio ai costi nascosti di spedizione e di gestione! <br /><br /> In SPS Fulfillment abbiamo una politica di prezzi trasparente e offriamo una tariffa fissa per la gestione di ogni ordine. Il nostro approccio innovativo quali vantaggi offre? Ci consente di contrattare con più corrieri, offrendo l'opzione più conveniente per ogni spedizione.",

      Handlingfeetitle: "Tariffe di gestione",
      Handlingfeesubtitle: "La nostra tariffa fissa di gestione include assistenza personalizzata, confezionamento professionale, conservazione sicura in magazzino e molto altro, garantendoti un'esperienza di shopping senza problemi ",
      Titlemap: "Spediamo in più di 10 Paesi",
      Perunit: "/ per unità", 
      Itemweight:"Peso Articolo",
      Itemsize:"Dimensione Articolo",

      Bestsellertitle: "SPS Dropshipping",
      sortproduct:"Ordina prodotto per",
      category1:"Best Seller",
      category2:"Miglior Valutazione",
      categorytitle:"Categorie",
      category3: "Moda Donna",
      category4: "Moda Uomo",
      category5: "Telefonia e telecomunicazioni",
      category6: "Informatica, ufficio e sicurezza",
      category7: "Elettronica di consumo",
      Filter:"Filtra",
      Loadmore:"Carica altro",
      productnumber:"200 Prodotti da Best Seller",

      Researchtitle: "Ottieni la tua analisi in 2 giorni",
      Researchsubtitle:"Prova il nostro nuovo servizio. Cerchiamo i prodotti più venduti per il tuo negozio o FBA. <br /> Recensioni illimitate fino a quando non sei soddisfatto del prodotto.",
      titlefirstcard:"Accesso a migliaia di prodotti ad alto tasso di conversione",
      subtitlefirstcard:"Non devi più preoccuparti di trovare il prodotto che vuoi perché noi forniamo un ampio catalogo tra cui scegliere",
      titlesecondcard:"Ricevi raccomandazioni per prodotti di alta qualità",
      subtitlesecondcard:"Ti forniremo raccomandazioni in tempo reale riguardo ai migliori prodotti disponibili al momento",
      titlethirdcard:"Ricerca di pubblico di Facebook",
      subtitlethirdcard:"Ti aiuteremo a ottenere il giusto pubblico tramite la pubblicità su Facebook",
      secondtitleresearch:"Per ogni ricerca, forniamo quanto segue",
      firstbenefittitle:"01. Analisi del prodotto",
      firstbenefitsubtitle:"Ci assicuriamo di fornirti dati dall'analisi che abbiamo su ogni prodotto in modo da trovare il miglior prodotto per te",
      secondbenefittitle:"02. Descrizione ottimizzata del prodotto",
      secondbenefitsubtitle:"Abbiamo un team che può aiutarti a compilare la migliore descrizione per ogni prodotto che venderai per ottenere il miglior risultato",
      thirdbenefittitle:"03. Video pubblicitari e miniature di alta qualità",
      thirdbenefitsubtitle: "Ci assicuriamo che ogni prodotto che abbiamo abbia un layout di annuncio video professionale e anche le migliori foto",
      fourthbenefittitle: "04. Targeting di Facebook",
      fourthbenefitsubtitle: "Ti daremo suggerimenti per il tuo target per massimizzare le vendite con l'aiuto di Facebook",
      fivebenefittitle: "05. Recensione prodotto personalizzata",
      fivebenefitsubtitle: "Visualizzeremo tutte le recensioni di ogni prodotto che abbiamo,  ricavate da Amazon e Alibaba",
      sixbenefittitle: "06. Dati concorrenti e tendenze",
      sixbenefitsubtitle: "Forniremo sempre i dati più recenti su ognuno dei tuoi concorrenti insieme alle tendenze del momento",
      sevenbenefittitle: "07. Trova il prodotto vincente da diverse nicchie",
      sevenbenefitsubtitle: "Stai impazzendo nella ricerca del miglior prodotto? Lascialo a noi, ti troveremo il miglior prodotto insieme alle informazioni sul profitto del prodotto",
      eightbenefittitle: "08. Strumento di spionaggio in tempo reale!",
      eightbenefitsubtitle: "Forniamo dati in tempo reale sulla tendenza del prodotto, ovvero su quanto quel prodotto viene effettivamente venduto al pubblico",
      buttonresearch: "Prova ora",

      Ourservicetitle: "I Nostri Servizi",
      Ourservicesubtitle: "Offriamo un servizio versatile e completo, dalla ricerca dei prodotti alla personalizzazione del pacchetto, dal pagamento in contanti alla consegna a casa del cliente e molto altro ancora. La lista è lunga e ogni cliente può esprimere la sua richiesta. Questo è ciò che ci distingue dai nostri concorrenti. <br /> Contattaci, effettueremo la ricerca e l'ordine del prodotto per te, lo stoccheremo nel nostro magazzino e lo spediremo al tuo cliente. È semplice come sembra, faremo tutto il lavoro per te.",
      Completeservicetitle: "Servizio Completo",
      Completeservice: "Benvenuti nel nostro Servizio Completo, progettato per rendere la tua esperienza di ecommerce il più scorrevole e priva di stress possibile. Con questo servizio, puoi sederti e lasciare che il nostro team faccia tutto il lavoro per te. Esploreremo e confronteremo i prezzi da migliaia di fornitori diversi per trovare il miglior affare per i prodotti di cui hai bisogno. <br /> Ci occuperemo dell'ordinazione, della confezione (inclusa la personalizzazione come le cartoline di ringraziamento, se desiderato) e della spedizione alla porta del tuo cliente entro 24-48 ore.",
      YourGoodstoOurWarehousetitle: "I Tuoi Prodotti al Nostro Magazzino",
      YourGoodstoOurWarehousesubtitle: "Se hai già i tuoi prodotti e hai solo bisogno di aiuto con l'imballaggio e la spedizione, il nostro servizio Your Goods to Our Warehouse è la soluzione perfetta per te. Questo servizio include tutto, dalla confezione alla personalizzazione e alla consegna rapida ed efficiente ai tuoi clienti, sfruttando al contempo le nostre attrezzature di ultima generazione del magazzino. Inviaci semplicemente i tuoi prodotti  e noi ci occuperemo del resto, rendendo il processo il più fluido possibile. <br /> Contattaci oggi per saperne di più e iniziare subito.",
      OurGoodstoYourWarehousetitle: "I Nostri Prodotti al Tuo Magazzino",
      OurGoodstoYourWarehousesubtitle: "Se stai cercando assistenza per la ricerca e l'acquisto di prodotti, il nostro servizio Our Goods to Your Warehouse è qui per aiutarti. Esploreremo il mercato alla ricerca delle migliori offerte dei prodotti di cui hai bisogno e li consegneremo direttamente al tuo magazzino. Ciò ti consentirà di concentrarti sugli altri aspetti del tuo business mentre noi ci occupiamo della fatica di trovare i migliori prodotti ai migliori prezzi.<br /> Contattaci oggi per saperne di più e fare il primo passo verso la crescita del tuo business.",
      SalesAssistancetitle: "Anticipo dello stock",
      SalesAssistancesubtitle: "SPS Fulfillment ha introdotto una soluzione innovativa per aiutare i nostri clienti a far crescere il proprio business: l'anticipo stock. Con questo servizio, i nostri clienti possono scegliere i prodotti che desiderano, e noi anticipiamo le spese per l'acquisto di tutto lo stock. Ciò significa che non dovrai preoccuparti dei costi iniziali, e potrai iniziare a vendere immediatamente. Inoltre, potrai ripagare il nostro investimento solo dopo che avrai incassato il tuo guadagno, garantendo la liquidità necessaria per far crescere il tuo business. Non perdere l'opportunità di sfruttare il nostro servizio di anticipo stock e rendi il tuo business ancora più profittevole con SPS Fulfillment.",
      Dropshippingtitle: "Dropshipping",
      Dropshippingsubtitle: "Il nostro servizio Dropshipping è un'offerta unica progettata per aiutarti a testare nuovi prodotti e costruire il tuo negozio online senza la seccatura della gestione dell'inventario. Con questo servizio, puoi offrire ai tuoi clienti una vasta gamma di prodotti senza dover effettivamente tenerli in magazzino e gestire l'inventario da solo.<br /> Chiedi al nostro team quali prodotti abbiamo oggi in magazzino.",
      Callcentertitle: "Call Center",
      Callcentersubtitle: "La nostra sottoscrizione al call center è progettata per massimizzare l'automazione del tuo business. Il nostro team di esperti verifica e conferma ciascun ordine prima della spedizione per evitare problemi di pagamento al momento della consegna. In caso di problemi di consegna o di restituzione del prodotto, ci occuperemo della risoluzione del problema stesso in modo che tu possa rilassarti mentre il tuo business cresce esponenzialmente.",

      titlecontactus: "Entra in contatto con noi oggi stesso",
      subtitlecontactus: "Se hai una domanda o vuoi unirti al nostro team, puoi inviarci <br /> direttamente un messaggio e ti risponderemo entro 24 ore",
      name:"Nome",
      phonenumber: "Numero di telefono",
      email: "Email",
      yourquestion:"La tua domanda",
      contactusbutton: "Invia domanda",

      Italy1:"Italia (Sede Legale)",
      Italy2:"Italia (Magazzino)",
      Ouroffice:"Le nostre sedi",
      Office1:"9 Via Borgonuovo, Milano 20121",
      Office2:"Affori, Milano 20162",

      question1:"Con che frequenza viene effettuato il rimborso in contrassegno?",
      question2:"Come posso connettere il mio negozio alla vostra piattaforma?",
      question3:"Avrò un sito? Dove posso tenere traccia delle mie consegne?",
      answer1:"La frequenza di rimborso del contrassegno è solitamente ogni 7 giorni, tuttavia ciò può essere modificato discutendone con il nostro team.",
      answer2:"Contatta il nostro team e una volta che hai ordinato i tuoi prodotti, ti invieremo un video dettagliato su come connettere il tuo negozio. Lo stato dei tuoi ordini verrà aggiornato automaticamente sul tuo negozio. È molto facile!",
      answer3:"Sì, certo. Il nostro team ti darà l'accesso a un sito personale, dove puoi tenere traccia di tutte le consegne e molto altro",

      thanksmessage: "Grazie per averci contattato! <br /> Abbiamo ricevuto il tuo messaggio e ti risponderemo entro 24 ore.",

      tileorderpage: "Facci sapere cosa vuoi ordinare",
      subtitleorderpage: "Compila il modulo descrivendo le tue esigenze e il nostro team ti risponderà <br /> entro 24 ore",
      whatproduct: "Quale prodotto stai cercando?",
      productquantity: "Quantità del prodotto",
      productvariation: "Variante del prodotto",
      SpecialRequest: "Richiesta speciale",
      SpecialRequesttext: "* Hai bisogno di una richiesta speciale che possiamo aiutarti a soddisfare? (Personalizzazione del prodotto o dell'imballaggio, provare un prodotto?). Faremo del nostro meglio per rispondere alle tue esigenze.",
      deliverydetails: "Dettagli di consegna",
      StandardDelivery: "Consegna standard",
      CashonDelivery: "Contrassegno",
      Services: "Servizi",
      CompleteService: "Servizio completo",
      SendyourgoodstoourwarehouseServices: "Invia i tuoi beni al nostro magazzino",
      Sendourgoodstoyourwarehouse: "Invia i nostri beni al tuo magazzino",
      SaleAssistance: "Anticipo del costo dello stock",
      Yes: "Sì",
      No: "No",
      Howcontact: "Come vuoi essere contattato",
      WhatsAppNumber: "Numero di WhatsApp",
      addphoto: "Aggiungi (foto, link, documento)",
      upload: "Carica la tua foto / documento qui",
      SendQuestion: "Invia domanda",

      titleproductrose: "Maglietta verde donna taglia doppia",
      productoverview: "Panoramica del prodotto",
      productrosedescription: "Decorazione per la casa Cupole in vetro soffiato a mano con base Regalo di nozze Rosa preservata in cloche di vetro",
      titleanalytics: "Analisi dell'impegno",

      priceCards: {
        size: "Dimensioni",
        weight: "Peso",
        price: "Prezzo",
      },
      headers: [
        {
          name: "Home",
          link: "/",
          color: "#fff",
        },
        {
          name: "Chi Siamo",
          link: "/about-us",
          color: "#000",
        },

        { name: "Prodotti", 
          dropdown: [
            { name: "Dropshipping", link: "/best-seller", color: "#000" },
            { name: "Negozio", link: "https://spsblog.myshopify.com/collections" , color: "#000"}
          ],
          
        },
        {
          name: "Prezzi",
          link: "/pricing",

          color: "#fff",
        },
        {
          name: "Blog",
          link: "https://spsfulfillmentstore.store/blogs/sps-blog",

          color: "#fff",
        },
        {
          name: "Ricerca",
          link: "/product-research",
          color: "#fff",
        },
        {
          name: "Servizi",
          link: "/our-services",
          color: "#fff",
        },
        {
          name: "Contattaci",
          link: "/contact-us",
          color: "#000",
        },
      ],
      benifit: [
        {
          id: 1,
          title: "Ricerca del prodotto ",
          description:
            "Non perdere più tempo a faticare per trovare i migliori prodotti ai migliori prezzi. Lascia che il nostro team di esperti si occupi del lavoro pesante per te tramite il nostro servizio Ricerca del prodotto.",
          icon: "truck-delivery",
          route: "/our-services",
        },
        {
          id: 2,
          title: "Consegna in 24 ore",
          description:
            "Ricevi i tuoi prodotti consegnati in soli 24 ore con il nostro servizio di magazzino veloce ed efficiente. Scegli SPS per tutte le tue esigenze e goditi tempi di consegna fulminei. Scegli SPS per le tue spedizioni!",
          icon: "user-search",
          route: "/our-services",
        },
        {
          id: 3,
          title: "Offriamo il pagamento alla consegna",
          description:
            "Il pagamento alla consegna è un'opzione popolare per molti acquirenti online e con SPS puoi offrirlo ai tuoi clienti con facilità. Scegli SPS e porta il tuo business al livello successivo.",
          icon: "cash",
          route: "/pricing",
        },
        {
          id: 4,
          title: "Anticipiamo il costo dello stock",
          description:"SPS Fulfillment copre il 100% dei costi del tuo stock, in modo che tu possa concentrarti sulle vendite. Mettendo a dispozione un catalogo infinito senza preoccupazioni finanziarie.",
          icon: "cross",
          route: "/our-services",
        },
      ],
      order: [
        {
          id: 1,
          name: "Inizia",
          description:
            "Inviaci una foto del prodotto che vuoi vendere. Con un algoritmo innovativo troveremo il miglior prezzo per te tra i nostri fornitori.",
          anchor: "Creare un account",
          preview: "step-1",
          route: "/our-services",
        },
        {
          id: 2,
          name: "Ottieni un preventivo",
          description:
            "Ti invieremo un preventivo dettagliato con il prezzo e il tempo di spedizione inclusi. Ci occupiamo di tutto.",
          anchor: "Saperne di più",
          preview: "step-2",
          route: "/pricing",
        },
        {
          id: 3,
          name: "Ordina",
          description:
            "Conferma il preventivo e ordineremo e spediremo il tuo inventario desiderato al nostro magazzino.",
          anchor: "Seleziona Prodotto",
          preview: "step-3",
          route: "/order",
        },
        {
          id: 4,
          name: "Inizia a vendere",
          description:
            "Una volta che avremo ricevuto l'inventario, sincronizzeremo i tuoi canali di vendita alla nostra piattaforma.",
          anchor: "Saperne di più",
          preview: "step-1",
          route: "/best-seller",
        },
      ],
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    // lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    debug: true,
    detection: {
      order: ["path", "localStorage", "htmlTag", "cookie"],
      caches: ["localStorage", "cookie"], // cache user language on
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    fallbackLng: "it",
    lang:'it',
  });

export default i18n;
